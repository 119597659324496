import React, {
  memo,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import style from './style.less'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Icon, { IconSizes } from '../Icon/Icon'
import FilterMenu from '../FilterMenu/FilterMenu'
import Button from '../Button'
import Autocomplete from '@mui/material/Autocomplete'
import { SavedQuery } from './SearchQueryBar'
import { useSearchParams } from 'react-router-dom'
import { useTagsStore } from '../stores/Tags'
import { getNLSSearch, SearchFilter } from '../../services/api/api'
import { ToastContext, ToastContextType } from '../../context/toastContext'
import { createTagsFromURL, queryToTags, sequenceToTags } from './utils'
import { useShallow } from 'zustand/react/shallow'
import { Version } from '../types'
import { ToastTypes } from '../Toast/Toast'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import WarningDialog from '../WarningDialog'
import { useDatasetStore } from '@yaak/nutron/src/stores/DatasetStore'

const uuid = require('uuid')

interface NLSAutocompleteProps {
  token: string
  textFieldRef: MutableRefObject<HTMLInputElement | null>
  onClearFilters: () => void
  savedQueries: SavedQuery[]
  onFilterMenuClick: (value: SearchFilter | null) => void
  onSaveButtonClick: () => void
  onSavedQueryClick: (savedQuery: SavedQuery) => void
  selectionEnabled: number
}

const NLSAutocomplete: React.FunctionComponent<NLSAutocompleteProps> = ({
  token,
  textFieldRef,
  onClearFilters,
  savedQueries,
  onFilterMenuClick,
  onSaveButtonClick,
  onSavedQueryClick,
  selectionEnabled,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [inputValue, setInputValue] = useState('')
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [query, setQuery] = useState<string | null>(null)
  const context = searchParams.get('context')
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const [showWarningDialog, setShowWarningDialog] = useState(false)
  const [continueSearch, setContinueSearch] = useState(false)

  const { dataset } = useDatasetStore(
    useShallow((state) => ({
      dataset: state.dataset,
    }))
  )

  useEffect(() => {
    const continueSearchEnabled = localStorage.getItem('continueSearch')
    if (continueSearchEnabled) {
      setContinueSearch(JSON.parse(continueSearchEnabled))
    }
  }, [])

  const onKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && query) {
        searchParams.set('scenarios', 'search')
        setSearchParams(searchParams)
        const result = await getNLSSearch({
          query,
          token,
          onAlert: setShowToast,
        })

        const queryTags = queryToTags(result?.query)
          .concat(queryToTags(result?.filters))
          .concat(sequenceToTags(result?.sequenceQuery?.sequence))
          .join('&')

        if (!queryTags) {
          setShowToast({
            text: 'Natural language optimization required to handle this search.',
            type: ToastTypes.info,
          })
        }

        if (dataset) {
          const createdTags = createTagsFromURL(queryTags, dataset)
          createdTags.length > 0 &&
            update(createdTags, result?.sequenceQuery?.duration)
        }
      }
    },
    [token, query, context, dataset, searchParams]
  )

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }, [])

  const onClick = useCallback(() => {
    setShowWarningDialog(!continueSearch && selectionEnabled > 0)
  }, [selectionEnabled, continueSearch])

  return (
    <>
      <Autocomplete
        id="search"
        multiple
        sx={{ width: '100%' }}
        componentsProps={{
          paper: {
            sx: {
              maxWidth: '100%',
            },
          },
        }}
        freeSolo
        inputValue={inputValue}
        onInputChange={(event, value, reason) => {
          if (event && event.type === 'blur') {
            setInputValue('')
          } else if (reason !== 'reset') {
            setInputValue(value)
          }
        }}
        getOptionKey={(option) => (option as SavedQuery).value}
        className={style.autocomplete}
        clearOnBlur={false}
        renderInput={(params) => (
          <TextField
            {...params}
            onClick={onClick}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onFocus={(e) => e.stopPropagation()}
            ref={textFieldRef}
            placeholder={'Search dataset'}
            className={style.searchInput}
            inputProps={{ ...params.inputProps, autoComplete: 'off' }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Icon
                      name={'Search'}
                      styles={{ fontSize: '1.25rem', color: '#7d8287' }}
                    />
                  </InputAdornment>
                </>
              ),
              endAdornment: (
                <>
                  {inputValue && (
                    <Icon
                      name={'Close'}
                      version={Version.v2}
                      className={style.deleteIcon}
                      onClick={() => setInputValue('')}
                    />
                  )}
                  <FilterMenu
                    onClick={onFilterMenuClick}
                    onClearFilters={onClearFilters}
                    showClearAction={!!searchParams.get('q')}
                  />
                  {tags && tags?.length > 0 && (
                    <Button
                      className={style.saveQuery}
                      onClick={onSaveButtonClick}
                      text={'Save'}
                      tertiary
                    />
                  )}
                </>
              ),
            }}
          />
        )}
        disabled={false}
        options={savedQueries}
        getOptionLabel={(option: any) => option.value}
        renderOption={(props, savedQuery) => {
          return (
            <li
              {...props}
              className={style.savedQueryOption}
              key={`${uuid.v4()}_${savedQuery.value}`}
              onClick={() => onSavedQueryClick(savedQuery)}
            >
              {savedQuery.value}
              <div className={style.optionActions}>
                <Icon
                  name={'Copy'}
                  className={style.icon}
                  onClick={(event) => {
                    event.stopPropagation()
                    savedQuery.onCopy?.(savedQuery.value)
                  }}
                />
                <Icon
                  name={'Edit'}
                  className={style.icon}
                  onClick={(event) => {
                    event.stopPropagation()
                    savedQuery.onEdit?.(savedQuery)
                  }}
                />
                <Icon
                  name={'Delete'}
                  className={style.icon}
                  onClick={(event) => {
                    event.stopPropagation()
                    savedQuery.onDelete?.(savedQuery)
                  }}
                />
              </div>
            </li>
          )
        }}
        forcePopupIcon={false}
        isOptionEqualToValue={() => false}
      />
      <WarningDialog
        showAgainOption={'continueSearch'}
        icon={
          <Icon
            version={Version.v2}
            name={'Warning'}
            size={IconSizes.large}
            className={style.warningIcon}
          />
        }
        isOpen={showWarningDialog}
        dialogContentText={
          <Typography
            version={Version.v2}
            type={TypographyTypes.title}
            size={TypographySizes.large}
          >
            Searching now will erase your selection
          </Typography>
        }
        dialogContentText2={
          <Typography version={Version.v2} type={TypographyTypes.body}>
            Please save it to a collection first before a new or modified
            search.
          </Typography>
        }
        dialogTitle={''}
        onSubmit={async () => {
          setContinueSearch(true)
          setShowWarningDialog(false)
        }}
        onCancel={() => {
          setShowWarningDialog(false)
        }}
        buttons={{
          cancel: 'Cancel',
          submit: 'Continue',
        }}
      />
    </>
  )
}

export default memo(NLSAutocomplete)
