import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import style from './style.less'
import Typography, { TypographyTypes } from '../Typography'
import { getTagValue, useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import { useTag } from '../../customHooks/useTag'
import { OPERATORS_URL_MAPPING } from '../SearchQueryBar/utils'

export interface FilterMenuMultiTagProps {
  tagKey: string
  searchValue: string
}

const FilterMenuMultiTag = ({
  tagKey,
  searchValue,
}: FilterMenuMultiTagProps) => {
  const [checked, setChecked] = useState<Record<string, boolean>>({})
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const tag = useTag(tagKey, tags)

  useEffect(() => {
    if (tag) {
      const value = getTagValue(tag)
      setChecked(((prev: Record<string, any>) => {
        prev &&
          Object.keys(prev).forEach((key) => {
            prev[key] = false
            return prev
          })
      }) as any)
      if (value) {
        value.split(',')?.forEach((v) => {
          setChecked((prev) => {
            return {
              ...prev,
              [v]: true,
            }
          })
        })
      }
    }
  }, [tag, tags])

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.getAttribute('data-key')
      if (tag && tags) {
        const queryParamToEdit = tag.tagUrl.split(' ').filter((t) => t !== '')
        const ifAlreadyExists = queryParamToEdit.length > 2
        let newFullTag
        if (event.target.checked) {
          const newTag = ifAlreadyExists ? `,${value}` : ` ${value}`
          newFullTag = queryParamToEdit.join(' ') + newTag
        } else {
          const tagValue = queryParamToEdit.slice(2).join(' ')?.split(',')
          const updatedTagValue = tagValue.filter((v) => v !== value).join(',')
          newFullTag = `${queryParamToEdit[0]} ${queryParamToEdit[1]} ${updatedTagValue}`
        }

        tag.tagUrl = newFullTag
        const splitNewFullTag = newFullTag.split(' ')
        splitNewFullTag[0] = tag.displayName
        splitNewFullTag[1] = OPERATORS_URL_MAPPING[splitNewFullTag[1]]
        tag.tagValue = splitNewFullTag.join(' ')
        update(tags.filter((t) => (t.name === tagKey ? tag : t)))
      }
    },
    [tag, tags]
  )

  const filteredValues = useMemo(
    () =>
      tag?.values?.filter((val) =>
        val.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [tag, searchValue]
  )

  return (
    <div className={style.filterMenuMultiTags}>
      {filteredValues?.map((value, i) => {
        return (
          <div key={`${value}_${i}`} className={style.tagMultiValue}>
            <input
              type="checkbox"
              onChange={onChange}
              data-key={value}
              checked={checked?.[value]}
            />
            <Typography type={TypographyTypes.label}>{value}</Typography>
          </div>
        )
      })}
    </div>
  )
}

export default memo(FilterMenuMultiTag)
