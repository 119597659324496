import { useEffect } from 'react'

export function useClickOutside(
  ref: any,
  callback: (dependencies?: any) => void,
  dependencies?: any
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(dependencies)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, dependencies])
}
