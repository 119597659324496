const OPERATORS_MAPPING: Record<string, string> = {
  '=': '$eq',
  '!=': '$ne',
  '>': '$gt',
  '>=': '$gte',
  '<': '$lt',
  '<=': '$lte',
}

const NUMBERS = [
  'bitrate',
  'score',
  'speed',
  'brake_pedal_score',
  'brake_pedal',
  'steering_angle',
  'gas_pedal',
  'acceleration',
  'max_speed',
  'lane_count',
]

const VALUE_MAPPING: Record<string, Record<string, string>> = {
  gear: {
    Drive: 'D',
    Neutral: 'N',
    Reverse: 'R',
    Park: 'P',
    Breaking: 'B',
  },
}

const QUERY_MAPPING: Record<string, string> = {
  drive_id: 'session_id',
  road_attributes: 'map_feature',
  traffic_facilities: 'map_feature',
  traffic_junctions: 'map_feature',
}

const VALUE_UPPERCASE = [
  'drive_type',
  'turn_signal',
  'road_attributes',
  'traffic_facilities',
  'traffic_junctions',
]

const IS_FILTER = ['drive_type', 'driver_id', 'session_id', 'bitrate']

const shouldBeNumber = (type: string, value: string) =>
  NUMBERS.indexOf(type) !== -1 ? parseFloat(value) : value

const toCamelCase = (name: string) =>
  name
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('_', ''))

export const parseSearchParams = (params: string[]): any => {
  return params.reduce(
    (acc, param) => {
      const [queryName, operator, value] = param.split(' ')
      if (queryName) {
        const property = IS_FILTER.includes(
          QUERY_MAPPING[queryName] || queryName
        )
          ? 'filters'
          : 'query'
        acc[property][
          toCamelCase(QUERY_MAPPING[queryName] || queryName) as string
        ] = {
          [OPERATORS_MAPPING[operator as string]]: shouldBeNumber(
            queryName,
            VALUE_MAPPING[queryName as string]
              ? VALUE_MAPPING[queryName as string][value]
              : VALUE_UPPERCASE.includes(queryName)
              ? value?.toUpperCase()
              : value?.toLowerCase()
          ),
        }
      }
      return acc
    },
    {
      query: {},
      filters: {},
    } as any
  )
}
