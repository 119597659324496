export const findQueryById = (savedQueries: any, id: string) =>
  savedQueries.filter((query: any) => query.id === id)[0]

export const OPERATORS_MAPPING: Record<string, string> = {
  $eq: '=',
  $ne: '!=',
  $gt: '>',
  $gte: '>=',
  $lt: '<',
  $lte: '<=',
}

export const GROUPS = {
  GENERAL: 'GENERAL',
  DYNAMIC_DATA: 'DYNAMIC DATA',
  MAP_BASED_DATA: 'MAP-BASED DATA',
}

export const SUBGROUPS = {
  DRIVER_PERFORMANCE: 'Driver performance',
  ENVIRONMENTAL_CONDITION: 'Environmental topology',
  OBJECTS_AGENTS: 'Objects & agents',
  TRAFFIC_INFRASTRUCTURE: 'Traffic infrastructure',
  ENVIRONMENTAL_TOPOLOGY: 'Environmental topology',
}

export type OPERATORS_KEYS =
  | 'drive_type'
  | 'kit_id'
  | 'bitrate'
  | 'driver'
  | 'drive_id'
  | 'location'
  | 'incident'
  | 'score'
  | 'brake_pedal_score'
  | 'gas_pedal_score'
  | 'steering_angle_score'
  | 'lighting'
  | 'condition'
  | 'steering_angle'
  | 'brake_pedal'
  | 'gas_pedal'
  | 'gear'
  | 'speed'
  | 'acceleration'
  | 'turn_signal'
  | 'max_speed'
  | 'traffic_facilities'
  | 'traffic_junctions'
  | 'road_attributes'
  | 'road_type'
  | 'lane_count'
  | 'road_surface'
  | 'partner'

export type SUBGROUPS_OPERATORS_KEYS =
  | 'incident'
  | 'lighting'
  | 'steering_angle'
  | 'max_speed'
  | 'road_type'

export const GROUPS_MAPPING: Record<OPERATORS_KEYS, string> = {
  drive_type: GROUPS.GENERAL,
  kit_id: GROUPS.GENERAL,
  bitrate: GROUPS.GENERAL,
  driver: GROUPS.GENERAL,
  drive_id: GROUPS.GENERAL,
  location: GROUPS.GENERAL,
  partner: GROUPS.GENERAL,
  incident: GROUPS.DYNAMIC_DATA,
  score: GROUPS.DYNAMIC_DATA,
  brake_pedal_score: GROUPS.DYNAMIC_DATA,
  gas_pedal_score: GROUPS.DYNAMIC_DATA,
  steering_angle_score: GROUPS.DYNAMIC_DATA,
  lighting: GROUPS.DYNAMIC_DATA,
  condition: GROUPS.DYNAMIC_DATA,
  steering_angle: GROUPS.DYNAMIC_DATA,
  gas_pedal: GROUPS.DYNAMIC_DATA,
  brake_pedal: GROUPS.DYNAMIC_DATA,
  gear: GROUPS.DYNAMIC_DATA,
  speed: GROUPS.DYNAMIC_DATA,
  acceleration: GROUPS.DYNAMIC_DATA,
  turn_signal: GROUPS.DYNAMIC_DATA,
  max_speed: GROUPS.MAP_BASED_DATA,
  traffic_facilities: GROUPS.MAP_BASED_DATA,
  traffic_junctions: GROUPS.MAP_BASED_DATA,
  road_attributes: GROUPS.MAP_BASED_DATA,
  road_type: GROUPS.MAP_BASED_DATA,
  lane_count: GROUPS.MAP_BASED_DATA,
  road_surface: GROUPS.MAP_BASED_DATA,
}

export const SUBGROUPS_MAPPING: Record<SUBGROUPS_OPERATORS_KEYS, string> = {
  incident: SUBGROUPS.DRIVER_PERFORMANCE,
  lighting: SUBGROUPS.ENVIRONMENTAL_CONDITION,
  steering_angle: SUBGROUPS.OBJECTS_AGENTS,
  max_speed: SUBGROUPS.TRAFFIC_INFRASTRUCTURE,
  road_type: SUBGROUPS.ENVIRONMENTAL_CONDITION,
}

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

const titleCase = (str: string) =>
  str[0].toUpperCase() + str.slice(1).toLowerCase()

export const queryToTags = (query: any) => {
  const result: string[] = []
  if (query) {
    const keys = Object.keys(query)
    if (keys.length > 0) {
      keys.forEach((key) => {
        const operator = Object.keys(query[key])?.[0]
        if (query[key][operator]) {
          const value = query[key][operator]
          result.push(
            `${camelToSnakeCase(key)} ${OPERATORS_MAPPING[operator]} ${
              typeof value === 'string' ? titleCase(value) : value
            }`
          )
        }
      })
    }
  }
  return result
}
