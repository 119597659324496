import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react'
import style from './style.less'
import classNames from 'classnames'

const GridCheckboxElement = ({
  checked,
  onChange,
  idx,
  id,
}: {
  checked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  idx: number
  id: string
}) => {
  const onMouseOver = useCallback((idx: number) => {
    const row = [...document.querySelectorAll(`.gridItem${idx}`)]
    row.map((r) => r.classList.add(style.hoverRow))
  }, [])

  const onMouseLeave = useCallback((idx: number) => {
    const row = [...document.querySelectorAll(`.gridItem${idx}`)]
    row.map((r) => r.classList.remove(style.hoverRow))
  }, [])

  return (
    <div
      className={classNames(
        style.gridItem,
        'gridItemSelector',
        `gridItem${idx}`,
        style.itemCheckbox
      )}
      onMouseOver={() => onMouseOver(idx)}
      onMouseLeave={() => onMouseLeave(idx)}
    >
      <input type="checkbox" checked={checked} value={id} onChange={onChange} />
    </div>
  )
}

export interface IGridSelection {
  ids: string[]
  selectedIds: string[]
  onSelection: (ids: string[]) => void
}

const GridSelection = ({ ids, selectedIds, onSelection }: IGridSelection) => {
  const headerCheckboxRef = useRef<HTMLInputElement>(null)

  const onItemChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: id, checked } = e.target
    const newIds = checked
      ? [...selectedIds, id]
      : selectedIds.filter((i) => i !== id)
    onSelection(newIds)
    if (headerCheckboxRef.current) {
      headerCheckboxRef.current.checked = newIds.length === ids.length
      headerCheckboxRef.current.indeterminate =
        newIds.length !== 0 && newIds.length !== ids.length
    }
  }

  useEffect(() => {
    if (selectedIds.length === 0 && headerCheckboxRef.current) {
      headerCheckboxRef.current.checked = false
      headerCheckboxRef.current.indeterminate = false
    }
  }, [selectedIds])

  const onAllCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    onSelection(checked ? [...ids] : [])
  }

  return (
    <div className={classNames(style.gridCol, style.gridSelection)}>
      {ids.map((id, i) => (
        <div
          key={i}
          className={classNames(i === 0 ? style.inlineHeader : style.inline)}
        >
          <GridCheckboxElement
            idx={i}
            id={id}
            checked={
              i === 0 ? selectedIds.length > 0 : selectedIds.includes(id)
            }
            onChange={i === 0 ? onAllCheckboxChange : onItemChange}
          />
        </div>
      ))}
    </div>
  )
}

export default GridSelection
