import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import style from './style.less'
import Grid from '@yaak/components/src/Grid'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Toast from '@yaak/components/src/Toast'
import { toastType } from '@yaak/components/src/Toast/Toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import Divider from '@yaak/components/src/Divider'
import { useSmallScreenMatches } from '../../customHooks/useSmallScreenMatches'
import Empty from '../Empty'
import {
  getDatasetMappedDrives,
  getMappedDrives,
  HEADERS,
} from '@yaak/admin/src/helpers/drives'
import {
  Camera,
  datasetsSearch,
  getActiveSessions,
  getCollectionItems,
  getSessions,
  removeCollectionItems,
  Scenario,
  ScenarioData,
  SearchSession,
  Session,
  SessionData,
} from '@yaak/components/services/api/api'
import SearchFilter from '../SearchFilter'
import SearchQueryBar from '../SearchQueryBar'
import { useAuth0 } from '@auth0/auth0-react'
import { User as Auth0User } from '@auth0/auth0-spa-js/dist/typings/global'
import {
  areFiltersOnlySessionInfo,
  areParamsValid,
  getSelectionId,
  parseSearchParams,
} from './utils'
import { Version } from '../types'
import GridView from '@yaak/components/src/GridView/GridView'
import { ViewTypeValues } from '../SearchQueryBar/SearchQueryBar'
import classNames from 'classnames'
import SelectionActionBar from '@yaak/nutron/src/components/SelectionActionBar/SelectionActionBar'
import AddToCollectionsDialog from '@yaak/nutron/src/components/AddToCollectionsDialog'
import {
  hasSequenceTag,
  isValidDateTag,
  SEARCH_TYPE,
} from '../SearchQueryBar/utils'
import WarningDialog from '../WarningDialog'
import { isSandboxUser } from '@yaak/nutron/src/utils/sandbox'
import { useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import { useDatasetStore } from '@yaak/nutron/src/stores/DatasetStore'
import appConfig from '../../services/api/config'
import Icon from '../Icon'

const headerSort = [
  'Time',
  'Partner',
  'Type',
  'Vehicle',
  'Kit ID',
  'Driver',
  'Instructor',
]

const sortValue = [
  'startTimestamp',
  'partnerName',
  'driveType',
  'vin',
  'dongleId',
  'driverName',
  'instructorName',
]

const headerSortNutron = [
  'Incidents',
  'Map features',
  'Lighting',
  'Weather',
  'Type',
  'Kit ID',
  'Driver',
]

const sortValueNutron = [
  '',
  'incidentCount',
  'curriculumCount',
  'lighting',
  'weather',
  'driveType',
  '',
  'driver',
  '',
  '',
  'dongleId',
]

const PREDEFINED_METADATA_FIELDS = [
  { name: 'startTimestamp', displayName: 'Start', type: 'time' },
  { name: 'durationMs', displayName: 'Duration', type: 'duration' },
]
const COLLECTION_METADATA_FIELDS = [
  { name: 'type', displayName: 'Type', type: 'string' },
]
const SESSIONS_METADATA_FIELDS = [
  { name: 'scenarioCount', displayName: 'Scenarios', type: 'int8' },
]

interface SessionFilter {
  partnerId?: string
  userId?: string
  searchQuery?: string
  vin?: string
  dongleId?: string
  driveType?: number
  type?: string
}

interface CollectionData {
  id: string
  userId: string
  refetchCollection: () => void
}

interface DrivesOverviewProps {
  token: string
  filter?: SessionFilter
  withHeader?: boolean
  withInstructor?: boolean
  withPartner?: boolean
  withKit?: boolean
  dsAdmin?: boolean
  withDriver?: boolean
  withVehicle?: boolean
  fixedColumns?: number
  nutron?: boolean
  datasetId?: string
  searchQueryEnabled?: boolean
  version?: Version
  collection?: CollectionData
  onClick?: (sessionId: string, begin?: number, end?: number) => void
}

interface dateRange {
  sessionStart: string | undefined
  sessionEnd: string | undefined
}

const getUserRole = (auth0User?: Auth0User) => {
  const rolesKey =
    auth0User &&
    Object.keys(auth0User as any).filter(
      (key) => key?.indexOf('roles') !== -1
    )[0]
  return rolesKey ? auth0User?.[rolesKey].join(', ') : ''
}

const DrivesOverview: React.FunctionComponent<DrivesOverviewProps> = ({
  token,
  filter = {},
  withHeader = true,
  withInstructor = true,
  withPartner = true,
  withKit = true,
  withDriver = true,
  withVehicle = true,
  fixedColumns = 0,
  dsAdmin = false,
  nutron = false,
  datasetId,
  searchQueryEnabled = false,
  collection,
  version,
  onClick,
}): ReactElement => {
  const [loading, setLoading] = useState(true)
  const startedSearch = useRef<boolean | null>(null)
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)
  const [interval, setIntervalValue] = useState<NodeJS.Timeout>()
  const [sessions, setSessions] = useState<
    Session | ScenarioData | SearchSession
  >({} as Session)
  const [mappedDrives, setMappedDrives] = useState<any>()
  const [activeSessions, setActiveSessions] = useState<Session>({} as Session)
  const [toast, setShowToast] = useState<toastType | null>(null)
  const [fetchMoreData, setFetchMoreData] = useState<boolean>(false)
  const [sortOrder, setSortOrder] = useState<string>('DESC')
  const [sortBy, setSortBy] = useState<string>()
  const [searchQuery, setSearchQuery] = useState('')
  const [dateRange, setDateRange] = useState<dateRange | null>({
    sessionStart: undefined,
    sessionEnd: undefined,
  })
  const [totalSessions, setTotalSessions] = useState<number | undefined>()
  const [totalScenarios, setTotalScenarios] = useState<number | undefined>()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [showAddToCollectionModal, setShowAddToCollectionModal] =
    useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const smallScreenMatches = useSmallScreenMatches()
  const { user } = useAuth0()
  const context = searchParams.get('context')
  const isGuestUser = isSandboxUser()
  const { type, viewType, route } = useParams()

  const navigate = useNavigate()
  const sessionsLoadedRef = useRef<number>(0)
  const bgSearchTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const searchSessions = !!searchParams.getAll('q')[0]
  const dataRef = useRef<{
    type: string
    queryParams: string
    data: any
    sort: string
  }>({ type: '', queryParams: '', data: null, sort: '' })

  const view = collection ? viewType : type

  const { tags, sequenceDuration } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      sequenceDuration: state.sequenceDuration,
    }))
  )
  const { dataset } = useDatasetStore(
    useShallow((state) => ({
      dataset: state.dataset,
    }))
  )

  useEffect(() => {
    if (!searchParams.get('q')) {
      setTotalScenarios(undefined)
    }
  }, [searchParams])

  const formatDatasetsSearchData = useCallback((sessions: any) => {
    return sessions.data.map((session: any) => ({
      ...session,
      cameras: isGuestUser
        ? session.cameras?.map((camera: Camera) => {
            camera.url = camera.url.replace(
              appConfig.nutronApiUrl,
              appConfig.apiUrl
            )
            return camera
          })
        : session.cameras,
      ...(session.startOffset
        ? { startOffset: session.startOffset / 1000 }
        : {}),
      ...(session.endOffset ? { endOffset: session.endOffset / 1000 } : {}),
      ...(session.scenarios
        ? {
            scenarios: session.scenarios.map(
              ({ startOffset, endOffset }: any) => ({
                startOffset: startOffset / 1000,
                endOffset: endOffset / 1000,
              })
            ),
          }
        : {}),
    }))
  }, [])

  const flipType = (type: string) =>
    type === SEARCH_TYPE.SCENARIOS
      ? SEARCH_TYPE.SESSIONS
      : SEARCH_TYPE.SCENARIOS

  const fetchSearchSessions = async (type: string, offset?: number) => {
    const searchParams = new URLSearchParams(window.location.search)
    const begin = searchParams.get('begin')
    const end = searchParams.get('end')
    const queryParam = searchParams.get('q')
    const searchDataset = async (
      datasetId: string,
      type: string,
      data: any,
      currParams: string,
      flipSearchType: boolean = false
    ) => {
      const searchType = flipSearchType ? flipType(type) : type
      const newSessions = await datasetsSearch({
        id: datasetId,
        offset,
        token,
        ...(sortBy ? { sortBy, sortOrder } : {}),
        search: {
          ...data,
          range:
            begin && end
              ? {
                  from: begin,
                  to: end,
                }
              : {},
        },
        type: searchType,
        onAlert: setShowToast,
      })
      if (
        newSessions &&
        ((dataRef.current.type ? searchType !== dataRef.current.type : true) ||
          queryParam !== currParams)
      ) {
        const updatedData = formatDatasetsSearchData(newSessions)
        if (!flipSearchType) {
          setSessions({
            ...newSessions,
            data: offset
              ? (sessions.data || []).concat(updatedData)
              : updatedData,
          })
          setLoading(false)
        } else {
          dataRef.current.queryParams = queryParam || ''
          dataRef.current.type = searchType
          dataRef.current.data = { ...newSessions, data: updatedData }
        }
        searchType === SEARCH_TYPE.SESSIONS
          ? setTotalSessions(newSessions.metaData.totalCount)
          : setTotalScenarios(newSessions.metaData.totalCount)
      }
    }
    const data = parseSearchParams(tags, dataset)
    const isSessionInfo = areFiltersOnlySessionInfo(tags)
    if (queryParam && !isSessionInfo) {
      const tagParams = (tags || [])
        .map((tag) => encodeURIComponent(tag.tagUrl))
        .join('&')
      const validParams =
        areParamsValid(decodeURIComponent(queryParam)) &&
        tagParams === queryParam
      const validData = Object.keys(data).some(
        (key) => Object.keys(data[key]).length !== 0
      )
      if (
        datasetId &&
        data &&
        ((validParams && validData) ||
          isValidDateTag(tags) ||
          hasSequenceTag(tags))
      ) {
        if (data.sequenceQuery) {
          data.sequenceQuery.duration = sequenceDuration
        }
        const sortParams = sortBy ? `${sortBy}-${sortOrder}` : ''
        if (
          queryParam !== dataRef.current.queryParams ||
          sortParams !== dataRef.current.sort
        ) {
          const currParams = dataRef.current.queryParams
          searchDataset(datasetId, type, data, currParams)
          bgSearchTimeoutRef.current = setTimeout(
            () => searchDataset(datasetId, type, data, currParams, true),
            100
          )
          dataRef.current.sort = sortParams || ''
          dataRef.current.queryParams = queryParam || ''
        } else if (type === dataRef.current.type && !offset) {
          setSessions({
            ...dataRef.current.data,
            data: dataRef.current.data.data.map((v: any) => ({ ...v })),
          })
          dataRef.current.type = flipType(type)
          dataRef.current.data = {
            ...sessions,
            data: sessions.data.map((v) => ({ ...v })),
          }
          sessionsLoadedRef.current = 0
        }
      }
    } else if (datasetId && data) {
      searchDataset(datasetId, type, data, dataRef.current.queryParams)
      dataRef.current = { type: '', queryParams: '', data: null, sort: '' }
      setTotalScenarios(undefined)
      dataRef.current.queryParams = queryParam || ''
    }
  }

  useEffect(() => {
    return () => {
      bgSearchTimeoutRef.current && clearTimeout(bgSearchTimeoutRef.current)
    }
  }, [])

  const fetchSessions = async (searchType: string) => {
    await fetchSearchSessions(searchType)

    if (!nutron && token) {
      const activeSessions = await getActiveSessions({
        token,
        onAlert: setShowToast,
      })
      setActiveSessions(activeSessions)
    }
    startedSearch.current = null
  }

  useEffect(() => {
    const splitPathName = window.location.pathname.split('/')
    const routeFromLocation = splitPathName[splitPathName.length - 1]
    const searchType =
      routeFromLocation === ViewTypeValues.sessionLogs
        ? SEARCH_TYPE.SESSIONS
        : SEARCH_TYPE.SCENARIOS

    const searchParams = new URLSearchParams(window.location.search)
    const queryParam = searchParams.get('q')
    const tagParams = (tags || [])
      .map((tag) => encodeURIComponent(tag.tagUrl))
      .join('&')
    const validParams =
      (queryParam &&
        areParamsValid(decodeURIComponent(queryParam)) &&
        tagParams === queryParam) ||
      !queryParam
    if (
      !startedSearch.current &&
      !collection &&
      route &&
      (validParams || isValidDateTag(tags) || hasSequenceTag(tags))
    ) {
      if (isGuestUser || token) {
        fetchSessions(searchType)
        startedSearch.current = true
      }
    }
  }, [token, nutron, tags, collection, sortBy, sortOrder, datasetId, route])

  const fetchCollectionItems = async (offset?: number) => {
    if (collection?.id) {
      const collections = await getCollectionItems({
        collectionId: collection?.id,
        token,
        offset,
        onAlert: setShowToast,
      })

      if (collections) {
        setSessions({
          ...collections,
          data: offset
            ? (sessions.data || []).concat(collections.data)
            : collections.data,
        })
        setLoading(false)

        setTotalSessions(collections.metaData.totalCount)
      }
    }
  }

  useEffect(() => {
    collection && fetchCollectionItems()
  }, [collection])

  const onRemove = async () => {
    const items = selectedIds.map((id) => id.split('&').pop() || '')
    if (collection?.id) {
      await removeCollectionItems({
        collectionId: collection?.id,
        token,
        data: { items },
        onAlert: setShowToast,
      })
      collection.refetchCollection?.()
      setShowDeleteWarning(false)
      setSelectedIds([])
      fetchCollectionItems()
    }
  }

  useEffect(() => {
    const fetchSessions = async () => {
      const sessionsResp = await getSessions({
        token,
        sortBy,
        sortOrder,
        partnerId: filter.partnerId,
        userId: filter.userId,
        onAlert: setShowToast,
        searchQuery,
        ...dateRange,
        vin: filter.vin,
        dongleId: filter.dongleId,
        driveType: filter.driveType,
        type: filter.type,
      })

      setSessions(sessionsResp)
      setTotalSessions(sessionsResp.metaData.totalCount)
    }

    if (!nutron && searchQuery !== null) {
      const timeout = setTimeout(() => {
        token && fetchSessions()
      }, 500)
      setLoading(false)

      return () => clearTimeout(timeout)
    }
  }, [token, searchQuery, dateRange, sortBy, nutron])

  useEffect(() => {
    !nutron &&
      token &&
      setIntervalValue(
        setInterval(async () => {
          const activeSessions = await getActiveSessions({
            token,
            onAlert: setShowToast,
          })
          setActiveSessions(activeSessions)
        }, 5000)
      )

    return () => clearInterval(interval)
  }, [token, nutron])

  useEffect(() => {
    if (sessions?.data?.length > 0 && !datasetId) {
      const mappedDrives = getMappedDrives({
        sessionsData: sessions?.data as SessionData[],
        activeSessionsData: activeSessions?.data,
        setShowToast,
        totalCount: sessions.metaData.totalCount,
        matches: smallScreenMatches,
        navigate,
        options: {
          withPartner,
          withInstructor,
          fixedColumns: searchSessions ? 1 : fixedColumns,
          withKit,
          dsAdmin,
          withDriver,
          withVehicle,
          context,
          yaakAdmin: getUserRole(user).indexOf('yaak-admin') !== -1,
        },
      })
      setMappedDrives(mappedDrives)
    }
  }, [sessions?.data, activeSessions?.data, smallScreenMatches, searchSessions])

  useEffect(() => {
    if (datasetId && dataset && sessions?.data?.length > 0) {
      const mappedDrives = getDatasetMappedDrives({
        sessionsData: sessions?.data,
        setShowToast,
        datasetId,
        totalCount: sessions.metaData.totalCount,
        metadataFields: [
          ...(collection ? COLLECTION_METADATA_FIELDS : []),
          ...dataset.sessionMetadataFields,
          ...(route === ViewTypeValues.sessionLogs
            ? SESSIONS_METADATA_FIELDS
            : []),
          ...PREDEFINED_METADATA_FIELDS,
        ],
        context,
      })
      setMappedDrives(mappedDrives)
    }
  }, [datasetId, dataset, sessions, route])

  useEffect(() => {
    if (fetchMoreData) {
      datasetId
        ? collection
          ? fetchDataCollections2()
          : fetchDataNutron2()
        : fetchData2()
    }
    setFetchMoreData(false)
  }, [fetchMoreData, datasetId, sessions])

  const fetchData = () => {
    setFetchMoreData(true)
  }

  const fetchData2 = async () => {
    const morePages =
      sessions.data.length &&
      sessions.data.length < sessions.metaData.totalCount

    if (morePages && sessionsLoadedRef.current !== sessions.data.length) {
      sessionsLoadedRef.current = sessions.data.length
      const newSessionPage = await getSessions({
        token,
        onAlert: setShowToast,
        offset: sessions.data.length,
        sortBy,
        sortOrder,
        searchQuery,
        partnerId: filter.partnerId,
        userId: filter.userId,
        ...dateRange,
        dongleId: filter.dongleId,
        vin: filter.vin,
        driveType: filter.driveType,
        type: filter.type,
      })
      if (newSessionPage) {
        setSessions({
          ...sessions,
          data: [...sessions.data, ...newSessionPage.data] as SessionData[],
        })
        setFetchMoreData(false)
      }
    }
  }

  const fetchDataNutron2 = async () => {
    const morePages =
      sessions.data.length &&
      sessions.data.length < sessions.metaData.totalCount

    if (morePages && sessionsLoadedRef.current !== sessions.data.length) {
      sessionsLoadedRef.current = sessions.data.length
      await fetchSearchSessions(
        !route
          ? SEARCH_TYPE.SESSIONS
          : route && route === 'session-logs'
          ? SEARCH_TYPE.SESSIONS
          : SEARCH_TYPE.SCENARIOS || SEARCH_TYPE.SESSIONS,
        sessions.data.length
      )
      setFetchMoreData(false)
    }
  }

  const fetchDataCollections2 = async () => {
    const morePages =
      sessions.data.length &&
      sessions.data.length < sessions.metaData.totalCount

    if (morePages && sessionsLoadedRef.current !== sessions.data.length) {
      sessionsLoadedRef.current = sessions.data.length
      await fetchCollectionItems(sessions.data.length)
      setFetchMoreData(false)
    }
  }

  const onRowClick = (rowIndex: number) => {
    const id = datasetId
      ? (sessions.data[rowIndex] as any).sessionId ||
        (sessions.data[rowIndex] as any).id
      : searchSessions
      ? (sessions.data[rowIndex] as Scenario).sessionId
      : (sessions.data[rowIndex] as SessionData).id

    nutron
      ? onClick?.(
          id,
          ...(collection && (sessions.data[rowIndex] as any).type === 'session'
            ? []
            : [
                (sessions.data[rowIndex] as Scenario).startOffset,
                (sessions.data[rowIndex] as Scenario).endOffset,
              ])
        )
      : !dsAdmin && navigate(`/drives/${id}`)
  }

  const onHeaderClicked = (props: any) => {
    if (props.sortOrder[props.index] !== null) {
      setSortBy(nutron ? sortValueNutron[props.index] : sortValue[props.index])
      setSortOrder(props.sortOrder[props.index] ? 'DESC' : 'ASC')
    }
  }

  const onGridViewSort = useCallback((sortBy: string, order: string) => {
    setSortBy(sortBy)
    setSortOrder(order)
  }, [])

  const selectionIds = useMemo(
    () =>
      (sessions?.data || []).map((session) =>
        getSelectionId(session, !!collection)
      ),
    [sessions]
  )

  return (
    <div className={withHeader ? style.overview : style.overviewWithoutHeader}>
      <Typography
        type={TypographyTypes.headline}
        className={!withHeader ? style.hide : undefined}
      >
        {'Drives'}
      </Typography>
      <Divider className={!withHeader ? style.hide : undefined} />
      {!collection ? (
        searchQueryEnabled ? (
          <SearchQueryBar
            datasetId={datasetId}
            token={token}
            totalSessions={totalSessions}
            totalScenarios={totalScenarios}
            selectionEnabled={selectedIds.length}
          />
        ) : (
          <SearchFilter
            setSearchQuery={setSearchQuery}
            setDateRange={setDateRange}
          />
        )
      ) : null}
      {selectedIds.length > 0 && (
        <div className={style.selectionActionBarContainer}>
          <SelectionActionBar
            sessionCount={
              selectedIds.filter((id) => id.includes('session')).length
            }
            scenarioCount={
              selectedIds.filter((id) => id.includes('scenario')).length
            }
            onAdd={() => setShowAddToCollectionModal(true)}
            onRemove={
              collection && user?.sub === collection.userId
                ? () => setShowDeleteWarning(true)
                : undefined
            }
          />
        </div>
      )}
      <div
        className={classNames(
          withHeader
            ? style.gridContainer
            : nutron
            ? style.userGridContainerNutron
            : style.userGridContainer,
          view === ViewTypeValues.grid && sessions?.data?.length > 0
            ? style.gridView
            : undefined
        )}
        id="gridScrollContainer"
      >
        {loading ? (
          <ProgressBar />
        ) : sessions?.data?.length > 0 && mappedDrives?.rows?.length > 0 ? (
          !view || view === ViewTypeValues.list ? (
            <Grid
              enableLink={(nutron || collection) && sessions.data}
              data={mappedDrives}
              fetchData={fetchData}
              headerSort={nutron ? headerSortNutron : headerSort}
              onHeaderClicked={onHeaderClicked}
              onRowClick={onRowClick}
              version={version}
              rowSelection={
                searchParams.get('q') || datasetId
                  ? {
                      ids: selectionIds,
                      selectedIds,
                      onSelection: (newIds) => setSelectedIds(newIds),
                    }
                  : undefined
              }
              headers={HEADERS}
            />
          ) : (
            view === ViewTypeValues.grid && (
              <GridView
                token={token}
                sessionsData={sessions?.data}
                onSort={onGridViewSort}
                fetchData={fetchData}
                sortBy={sortBy}
                sortOrder={sortOrder}
                rowSelection={{
                  ids: selectionIds,
                  selectedIds,
                  onSelection: (newIds) => setSelectedIds(newIds),
                }}
              />
            )
          )
        ) : searchQuery && sessions?.data?.length === 0 ? (
          <Empty
            header={'Looks like no known scenario/episode matched your query.'}
            content={
              'Generate this scenario/episode with COSMOS/LMMs (coming soon).'
            }
          />
        ) : sessions?.data?.length === 0 && collection ? (
          <Empty
            button={{
              text: 'Search dataset',
              icon: <Icon name={'Search'} />,
              onClick: () => {
                navigate(`/datasets/${datasetId}/search/list?context=5s`)
              },
            }}
            header={'This collection is empty.'}
            content={'Fill it with session and/or scenario data of interest.'}
          />
        ) : nutron && sessions?.data?.length === 0 ? (
          <Empty
            header={"Looks like there's no data that matches your search."}
            content={'Try changing the parameters to get more results.'}
          />
        ) : (
          !nutron &&
          sessions?.data?.length === 0 && (
            <Empty
              header={'Whoops! Looks like there are no drives yet.'}
              content={
                'Drives will appear as soon as partners start expert and student drives or internal test drives are conducted.'
              }
            />
          )
        )}
      </div>
      {toast && <Toast toast={toast} setShowToast={setShowToast} />}
      {datasetId && showAddToCollectionModal && (
        <AddToCollectionsDialog
          token={token}
          selectedIds={selectedIds}
          datasetId={datasetId}
          isOpen={showAddToCollectionModal}
          onCancel={() => setShowAddToCollectionModal(false)}
          onCollectionSave={() => setSelectedIds([])}
        />
      )}
      {showDeleteWarning && (
        <WarningDialog
          icon={<div />}
          isOpen={showDeleteWarning}
          onSubmit={onRemove}
          onCancel={() => setShowDeleteWarning(false)}
          buttons={{
            submit: 'Delete',
            cancel: 'Cancel',
          }}
          dialogTitle={'Delete items from collection'}
          dialogContentText={'This action cannot be undone.'}
        />
      )}
    </div>
  )
}

export default DrivesOverview
