import { create } from 'zustand'
import { MosaicParent } from 'react-mosaic-component/src/types'
import { ROBOTICS_METADATA_SETTINGS } from './MetadataStore'
import { YAAK_SCHEMA_NAME_MAPPING } from '../utils/protobufParse'

export const PANEL_ID_CHART = 'CHART'
export const PANEL_ID_METADATA = 'METADATA'
export const PANEL_ID_MAP = 'MAP'
export const PANEL_ID_VIDEO = 'VIDEO'

export const availableCameras = [
  'CAM_FRONT_CENTER',
  'CAM_LEFT_BACKWARD',
  'CAM_REAR',
  'CAM_RIGHT_BACKWARD',
]

export type CAMERA_POSITIONS =
  | 'CAM_REAR'
  | 'CAM_FRONT_CENTER'
  | 'CAM_LEFT_BACKWARD'
  | 'CAM_RIGHT_BACKWARD'
  | 'CAM_RIGHT_FORWARD'
  | 'CAM_LEFT_FORWARD'
  | 'panel-settings'

export const CAMERA_POSITIONS_MAP: Record<string, number> = {
  CAM_REAR: 0,
  CAM_FRONT_CENTER: 1,
  CAM_LEFT_BACKWARD: 2,
  CAM_RIGHT_BACKWARD: 3,
  CAM_RIGHT_FORWARD: 4,
  CAM_LEFT_FORWARD: 5,
}

export type LAYOUT_NODE =
  | 'CAM_REAR'
  | 'CAM_FRONT_CENTER'
  | 'CAM_LEFT_BACKWARD'
  | 'CAM_RIGHT_BACKWARD'
  | 'CAM_RIGHT_FORWARD'
  | 'CAM_LEFT_FORWARD'
  | 'panel-video-settings'
  | 'panel-metadata'
  | 'panel-map'
  | 'panel-plot'
  | 'panel-settings'
  | 'observation/rgb/primary'

export interface IPanel {
  name: string
  type: string
  properties: {
    topics: string[]
  }
}

interface MosaicStoreState {
  config: MosaicParent<LAYOUT_NODE>
  update: (state: any) => void
  configPanels: IPanel[]
  updateConfigPanels: (panels: IPanel[]) => void
}
interface IConfig {
  panels: IPanel[]
  layout: MosaicParent<LAYOUT_NODE>
}

const AUTOMOTIVE_CONFIG: IConfig = {
  panels: [],
  layout: {
    direction: 'row',
    first: {
      direction: 'column',
      first: 'CAM_FRONT_CENTER',
      second: 'panel-map',
      splitPercentage: 50,
    },
    second: 'panel-plot',
    splitPercentage: 50,
  },
}

const ROBOTICS_CONFIG: IConfig = {
  panels: [
    {
      name: 'observation/rgb/primary',
      type: 'panel-video-image',
      properties: {
        topics: ['observation/rgb/primary'],
      },
    },
    {
      name: 'Metadata',
      type: 'panel-metadata',
      properties: {
        topics: Object.keys(ROBOTICS_METADATA_SETTINGS),
      },
    },
    {
      name: 'Plot',
      type: 'panel-plot',
      properties: {
        topics: ['observation/gripper_state'],
      },
    },
  ],
  layout: {
    direction: 'row',
    first: {
      direction: 'column',
      first: 'observation/rgb/primary',
      second: 'panel-metadata',
    },
    second: 'panel-plot',
  },
}

const YAAK_CONFIG: IConfig = {
  panels: [
    {
      name: 'CAM_LEFT_BACKWARD',
      type: 'panel-video-image',
      properties: {
        topics: [
          YAAK_SCHEMA_NAME_MAPPING.safetyScore,
          YAAK_SCHEMA_NAME_MAPPING.driveSessionInfo,
        ],
      },
    },
    {
      name: 'CAM_FRONT_CENTER',
      type: 'panel-video-image',
      properties: {
        topics: [
          YAAK_SCHEMA_NAME_MAPPING.safetyScore,
          YAAK_SCHEMA_NAME_MAPPING.driveSessionInfo,
        ],
      },
    },
    {
      name: 'CAM_RIGHT_BACKWARD',
      type: 'panel-video-image',
      properties: {
        topics: [
          YAAK_SCHEMA_NAME_MAPPING.safetyScore,
          YAAK_SCHEMA_NAME_MAPPING.driveSessionInfo,
        ],
      },
    },
    {
      name: 'CAM_REAR',
      type: 'panel-video-image',
      properties: {
        topics: [
          YAAK_SCHEMA_NAME_MAPPING.safetyScore,
          YAAK_SCHEMA_NAME_MAPPING.driveSessionInfo,
        ],
      },
    },
    {
      name: 'Map',
      type: 'panel-map',
      properties: {
        topics: [
          YAAK_SCHEMA_NAME_MAPPING.way,
          YAAK_SCHEMA_NAME_MAPPING.curriculumPoint,
          YAAK_SCHEMA_NAME_MAPPING.curriculumLineString,
          YAAK_SCHEMA_NAME_MAPPING.gnss,
          YAAK_SCHEMA_NAME_MAPPING.safetyScore,
        ],
      },
    },
    {
      name: 'Metadata',
      type: 'panel-metadata',
      properties: {
        topics: [
          YAAK_SCHEMA_NAME_MAPPING.vehicleState,
          YAAK_SCHEMA_NAME_MAPPING.vehicleMotion,
          YAAK_SCHEMA_NAME_MAPPING.gnss,
        ],
      },
    },
    {
      name: 'Plot',
      type: 'panel-plot',
      properties: {
        topics: [YAAK_SCHEMA_NAME_MAPPING.vehicleMotion],
      },
    },
  ],
  layout: {
    direction: 'column',
    first: {
      direction: 'row',
      first: 'CAM_LEFT_BACKWARD',
      second: {
        direction: 'row',
        first: 'CAM_FRONT_CENTER',
        second: 'CAM_RIGHT_BACKWARD',
      },
      splitPercentage: 33,
    },
    second: {
      direction: 'row',
      first: 'panel-map',
      second: 'panel-plot',
      splitPercentage: 50,
    },
    splitPercentage: 50,
  },
}

export enum datasetTypes {
  YAAK = 'yaak',
  Automotive = 'automotive',
  Robotics = 'robotics',
}

export const datasetTypeMap: Record<string, IConfig> = {
  [datasetTypes.YAAK]: YAAK_CONFIG,
  [datasetTypes.Automotive]: AUTOMOTIVE_CONFIG,
  [datasetTypes.Robotics]: ROBOTICS_CONFIG,
}

const CONFIG: MosaicParent<LAYOUT_NODE> = {
  direction: 'column',
  first: {
    direction: 'row',
    first: 'CAM_LEFT_BACKWARD',
    second: {
      direction: 'row',
      first: 'CAM_FRONT_CENTER',
      second: 'CAM_RIGHT_BACKWARD',
    },
    splitPercentage: 33,
  },
  second: {
    direction: 'row',
    first: 'panel-map',
    second: {
      direction: 'row',
      first: 'panel-metadata',
      second: 'panel-plot',
    },
    splitPercentage: 33,
  },
  splitPercentage: 50,
}

export const useMosaicStore = create<MosaicStoreState>((set) => ({
  config: CONFIG,
  configPanels: [],
  updateConfigPanels: (configPanels) => set(() => ({ configPanels })),
  update: (change) => {
    set((state) => {
      return typeof change === 'string'
        ? {
            config: change,
          }
        : {
            config:
              typeof state.config === 'string'
                ? {
                    ...change,
                  }
                : {
                    ...(state.config as Object),
                    ...change,
                  },
          }
    })
  },
}))
