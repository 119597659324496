import React from 'react'
import style from './style.less'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '../Typography/Typography'

type BreadcrumbType = {
  text: string | React.ReactNode
  url?: string
}

export interface BreadcrumbsProps {
  second?: BreadcrumbType
  first: BreadcrumbType
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({
  second,
  first,
  onClick,
}) => (
  <div aria-label="breadcrumb" className={style.breadcrumbs}>
    {first.url ? (
      <Typography
        type={TypographyTypes.label}
        url={first.url}
        size={TypographySizes.large}
        className={style.first}
      >
        {first.text}
      </Typography>
    ) : (
      <Typography
        type={TypographyTypes.label}
        size={TypographySizes.large}
        className={style.beforeSecond}
        onClick={onClick}
      >
        {first.text}
      </Typography>
    )}
    {second && (
      <>
        <>{`->`}</>
        <Typography type={TypographyTypes.label} size={TypographySizes.large}>
          {second.text}
        </Typography>
      </>
    )}
  </div>
)

export default Breadcrumbs
