import React, {
  useEffect,
  forwardRef,
  useState,
  useCallback,
  useRef,
} from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player'

// Url expires after 15 mins. Refresh time set to 14 min 50 secs.
const URL_REFRESH_TIME = (14 * 60 + 50) * 1000

interface PlayerProps extends ReactPlayerProps {
  url?: string
  token: string
  withAuthentication: boolean
  maxBufferLength: number
  maxBufferSize: number
  maxMaxBufferLength: number
  startPosition: number
}

const Player = forwardRef<ReactPlayer, PlayerProps>(
  (
    {
      url,
      token,
      withAuthentication,
      maxBufferLength,
      maxBufferSize,
      maxMaxBufferLength,
      startPosition,
      ...props
    },
    ref
  ) => {
    const [videoUrl, setVideoUrl] = useState<string | undefined>()
    const refreshUrlIntervalRef = useRef<ReturnType<typeof setInterval> | null>(
      null
    )

    const fetchPlaylist = async () => {
      if (!withAuthentication && url && token) {
        try {
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          if (!response.ok) {
            throw new Error('bad response code')
          }

          const blob = await response.blob()
          const blobURL = URL.createObjectURL(blob)
          setVideoUrl(blobURL)
        } catch (error) {
          throw Error(`Fetching playlist failed: ${error}`)
        }
      }
    }

    useEffect(() => {
      if (!withAuthentication && url && token) {
        refreshUrlIntervalRef.current = setInterval(() => {
          fetchPlaylist()
          props?.updatePlaying?.(false)
        }, URL_REFRESH_TIME)
        fetchPlaylist()
      } else {
        setVideoUrl(url)
      }
      return () => {
        refreshUrlIntervalRef.current &&
          clearInterval(refreshUrlIntervalRef.current)
      }
    }, [url, withAuthentication, token])

    const xhrSetup = useCallback(
      (xhr: XMLHttpRequest) => {
        withAuthentication &&
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      },
      [withAuthentication, token]
    )

    return (
      <ReactPlayer
        url={videoUrl}
        ref={ref}
        config={{
          file: {
            forceHLS: true,
            hlsVersion: '1.5.7',
            hlsOptions: {
              maxBufferLength,
              maxBufferSize,
              maxMaxBufferLength,
              startPosition,
              maxFragLookUpTolerance: 0,
              xhrSetup,
            },
          },
        }}
        {...props}
      />
    )
  }
)

Player.displayName = 'Player'

export default Player
